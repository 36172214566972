// this function creates a fallback for IE browser which does not support URL function
import _ from 'lodash';
import url from 'url';
import path from 'path';
import { URI } from '@core/uri';

import { getConfigVar } from '../config';
import { hashToPath } from '../history';
import { stringifyQuery, parseQuery } from '../query';

export const createURL = (u, host) => {
  let newURL = {};

  // Try to add protocol for URL that starts from variable bracket to be able to parse it
  if (typeof u === 'string' && u.charAt(0) === '{') {
    u = 'http://' + u;
  }

  if (host && host.charAt(0) === '{') {
    host = 'http://' + host;
  }

  if (typeof URL === 'function') {
    try {
      newURL = new URL(u);
    } catch (e) {
      try {
        newURL = new URL(`${host}${u}`);
      } catch (e) {
        return {};
      }
    }
  } else if (typeof url !== typeof undefined) {
    try {
      newURL = url.parse(u);
    } catch (e) {
      try {
        newURL = url.parse(`${host}${u}`);
      } catch (e) {
        return {};
      }
    }
  } else if (typeof document !== typeof undefined) {
    newURL = document.createElement('a');
    newURL.href = u;
  }

  // create a plain object
  newURL = {
    hash: newURL.hash,
    host: newURL.host,
    hostname: newURL.hostname,
    href: newURL.href,
    origin: newURL.origin,
    password: newURL.password,
    pathname: newURL.pathname === 'blank' ? '' : newURL.pathname,
    port: newURL.port,
    protocol: newURL.protocol,
    search: newURL.search,
    searchParams: newURL.searchParams,
    username: newURL.username,
  };

  // replace brackets in the URL (mostly used for variables)
  for (const field in newURL) {
    if (
      newURL.hasOwnProperty(field) &&
      newURL[field] &&
      ['href', 'pathname', 'host', 'hostname', 'origin'].includes(field)
    ) {
      newURL[field] = newURL[field]
        .replace(/\%24/gi, '$')
        .replace(/\%7B/gi, '{')
        .replace(/\%7D/gi, '}');
    }
  }

  return newURL;
};

export const isValidUrl = u => {
  const u2 = createURL(u);
  return u2.host;
};

export const cleanSlashes = (path, options = {}) => {
  // don't remove slashes for absolute URLs
  if (!path) return path;

  const { trimStart, trimEnd } = Object.assign({ trimStart: false, trimEnd: true }, options);

  // if we have a protocol like https://
  if (/:\/\//.test(path)) {
    // Dont replace the double slashes in https://
    return path
      .split('://')
      .map(p => cleanSlashes(p, { trimStart: true, trimEnd }))
      .join('://');
  }

  // Replace 2+ slashes with 1 slash
  let cleaned = _.replace(path, /\/{2,}/g, '/');

  // Remove ending slash
  if (trimEnd) {
    cleaned = cleaned.replace(/\/$/, '');
  }

  if (trimStart) {
    cleaned = _.replace(cleaned, /^\//, '');
  }

  if (!cleaned) {
    return '/';
  }

  return cleaned;
};

export const buildPathParts = (path, base) => {
  const pathParts = [];
  const newPath = base ? path.replace(base, '') : path;
  const parts = newPath.split('/');

  if (parts.length && parts[0] === '') {
    parts.shift();
  }

  for (const i in parts) {
    if (!Object.prototype.hasOwnProperty.call(parts, i)) {
      continue;
    }

    const part = parts[i];
    if (
      part.match(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
      )
    ) {
      pathParts.push(part);
    } else if (i === parts.length - 1) {
      // Try to split by file extention, basically a period.
      const pathsExt = part.split('.');
      if (pathsExt.length > 1) {
        if (pathsExt[0] === '') {
          pathsExt.shift();
        }
        pathParts.push(pathsExt[0]);
        pathsExt.shift();
        pathParts.push(pathsExt.join('.'));
      } else {
        pathParts.push(part);
      }
    } else {
      pathParts.push(part);
    }
  }

  return pathParts;
};

export const getAnchorSearch = location => {
  const rawAnchor = location.anchor || '';
  const arr = rawAnchor.split('?');

  return arr.length > 1 ? arr[1] : null;
};

export const getSearchQs = location => {
  const rawQs = location.search || '';
  const qs = rawQs.replace('?', '');

  return qs ? parseQuery(qs) : {};
};

export const getAnchorQs = location => {
  const anchorQs = getAnchorSearch(location);

  return anchorQs ? parseQuery(anchorQs) : {};
};

export const extractParams = path => {
  const params = [];

  const matchParam = /({([^}]+)}|%7B([^}]+)%7D)/gi;
  let match = matchParam.exec(path);
  while (match != null) {
    params.push(match[2]);
    match = matchParam.exec(path);
  }

  return params;
};

export const getAllParams = location => {
  return _.merge({}, getAnchorQs(location), getSearchQs(location));
};

export const getHashParts = location => {
  const params = {};

  if (typeof location.hash === 'string') {
    location.hash
      .slice(1)
      .split('&')
      .forEach(param => {
        const [key, value] = param.split('=');

        params[key] = value;
      });
  }

  return params;
};

export const replacePathParams = (path, params = {}) => {
  let replacedPath = path;
  const matches = extractParams(path);

  for (let match of matches) {
    const value = _.get(params, match);

    if (!_.isUndefined(value) && !(typeof value === 'string' && _.isEmpty(value))) {
      const regex = new RegExp(_.escapeRegExp(`{${match}}`), 'g');
      replacedPath = replacedPath.replace(regex, value);
    }
  }

  return replacedPath;
};

export const extractQueryFilters = (query = {}) => {
  const filters = {};

  _.forEach(query, (v, k) => {
    if (_.startsWith(k, 'f_')) {
      filters[k.substring(2)] = v.split(',');
    }
  });

  return filters;
};

export const isExternalLink = url => {
  const reg = new RegExp('^mailto:|http(s)?://|tel:');
  return reg.test(url);
};

export const urlSource = ({ u } = {}) => {
  let source = '';
  if (_.get(u, [0]) === '#') {
    source = 'local';
  } else if (_.includes(u, getConfigVar('SL_API_HOST')) || _.get(u, [0]) === '.') {
    source = 'internal';
  } else {
    source = 'external';
  }
  return { source: source };
};

export const stoplightResourceIdentifier = ({ u } = {}) => {
  if (urlSource({ u }).source === 'internal') {
    const newURL = url.parse(u);
    const resources = newURL.pathname.split('/');

    // relative ref
    if (resources[0] === '.') {
      return {
        file: resources[1],
      };
    }
    // for cases when the file contains slashes
    const file = resources.slice(3).join('/');
    let id = resources.slice(1, 3).join(':') + `:${file}`;

    const projectParts = resources[1].split('-');

    return {
      projectId: parseInt(projectParts[0]) || projectParts[0],
      projectPath: projectParts[1] ? projectParts[1] : null,
      ref: resources[2],
      file,
      id,
    };
  }
  return {};
};

export const getUrlTarget = ({ u } = {}) => {
  if (_.get(_.split(u, '#/'), [1])) {
    let hash = `#/${u.split('#/')[1]}`;
    const path = hashToPath({ hash });
    return { hash, path };
  }
  return {};
};

export const getFileFromURL = ({ u } = {}) => {
  let file = {};
  if (!_.isEmpty(u)) {
    const parsed = url.parse(u);
    if (_.get(parsed, 'pathname')) {
      const f = path.basename(parsed.pathname);
      if (f) {
        file = { file: path.basename(parsed.pathname) };
      }
    }
  }
  return file;
};

export const formats = ['oas2', 'oas3', 'scenarios', 'hub', 'prism'];
export const extensions = ['json', 'yml', 'yaml', 'md', 'html', 'css'];

// our files will always be in form foo-bar.format.languange
// assume external oas2... files would look like fileNameoas2.yml?
export const fileFormat = ({ file } = {}) => {
  if (!file) return {};

  const splitPath = _.split(file, '.');
  const fileFormat = _.toLower(_.nth(splitPath, -2));
  const fileLanguage = _.toLower(_.last(splitPath));

  let language;
  if (_.includes(extensions, fileLanguage)) {
    language = fileLanguage;
  }

  let format;
  // Markdown, HTML, & CSS don't have a file format
  if (!_.includes(['md', 'html', 'css'], language) && _.includes(formats, fileFormat)) {
    format = fileFormat;
  }

  return { file, format, language };
};

export const fileExtension = file => {
  if (!file) {
    return;
  }

  return path.extname(file).slice(1);
};

export const dataFormat = ({ data } = {}) => {
  let format = null;
  const keys = _.keys(data);
  if (_.includes(keys, 'swagger')) {
    format = 'oas';
  } else if (_.includes(keys, 'pages')) {
    format = 'hub';
  } else if (_.includes(keys, 'scenarioVersion')) {
    format = 'scenario';
  }

  return format;
};

// Helper function take URL string, remove ?foo=bar params, and return an object with those params
export const getQueryParams = path => {
  const params = {};

  // removes #path if existing
  const p = _.split(path, '#')[0];

  const queryString = _.split(p, '?')[1];

  if (!queryString) {
    return {};
  }

  const queries = _.split(queryString, '&');

  _.forEach(queries, query => {
    const q = _.split(query, '=');
    params[q[0]] = q[1];
  });

  return params;
};

// Helper function take URL string and query params, add to url
export const buildQueryString = ({ url = '', params = {} }) => {
  if (_.isEmpty(url) && _.isEmpty(params)) return {};

  let result = {};

  const oldParams = getQueryParams(url);
  const newParams = _.merge(oldParams, params);

  // removes #path if existing
  const p = _.split(url, '#')[0];
  const hash = _.split(url, '#')[1];

  // removes old queryString
  let path = _.split(p, '?')[0];

  let queryList = [];

  _.forEach(newParams, (value, name) => {
    if (!_.isEmpty(value) || _.isFinite(value)) {
      queryList.unshift(`${name}=${value}`);
    }
  });

  if (queryList.length) {
    let queryString = '';
    for (const i in queryList) {
      if (Number(i) === 0) {
        queryString = `?${queryList[i]}`;
      } else {
        queryString = `${queryString}&${queryList[i]}`;
      }
    }
    path = `${path}${queryString}`;
    result.queryString = queryString;
  }

  // re-add hash if existing
  if (hash) path = `${path}#${hash}`;

  result.url = path;

  return result;
};

// Helper function take URL string, strip queryString, return both
export const removeQueryString = path => {
  if (!path) return {};

  const result = {};

  // removes #path if existing
  const hash = _.split(path, '#')[1];
  let p = _.split(path, '#')[0];

  // removes query string if existing
  const queryString = _.split(p, '?')[1];
  p = _.split(p, '?')[0];

  // re add hash if existing
  if (hash) p = `${p}#${hash}`;

  result.url = p;

  if (queryString) result.queryString = `?${queryString}`;

  return result;
};

export const buildExportUrl = ({
  projectId,
  branch,
  filePath,
  fragment,
  deref,
  host = getConfigVar('SL_API_HOST'),
}) => {
  const query = stringifyQuery({
    projectId,
    branch,
    path: filePath,
    deref,
  });

  let exportUrl = `${host}/files.export?${query}`;

  if (fragment) {
    exportUrl = `${exportUrl}#${fragment}`;
  }

  return exportUrl;
};
